/* Global Styles */
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    display: flex;
    font-family: Arial, sans-serif;
}

/* Sidebar Styles */
.sidebar {
    width: 250px;
    padding: 15px;
    background-color: #f3f4f6;
    border-right: 1px solid #d1d5db;
}

.sidebar h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.sidebar ul {
    list-style-type: none;
    padding-left: 0;
}

.sidebar li {
    margin-bottom: 5px;
}

.sidebar ul ul {
    margin-left: 20px;
}

/* Content Styles */
.content {
    padding: 20px;
    flex: 1;
}

header h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

header h3 {
    font-size: 18px;
    color: #6b7280;
    margin-bottom: 5px;
}

header p {
    color: rgb(69, 72, 77);
    margin-bottom: 10px;
}

.skill-level h2 {
    font-size: 20px;
    margin-top: 20px;
}

.skill-level h3 {
    font-size: 16px;
    margin-top: 10px;
}

.skill-level ul {
    list-style-type: disc;
    margin-left: 20px;
}

.skill-level p {
    margin-top: 10px;
    color: #4b5563;
}

/* Responsive Design */

/* For tablets and smaller devices */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        /* Stack sidebar and content vertically */
    }

    .sidebar {
        width: 100%;
        /* Full width for sidebar on smaller screens */
        border-right: none;
        border-bottom: 1px solid #d1d5db;
    }

    .content {
        padding: 15px;
    }

    header h1 {
        font-size: 22px;
    }

    header h3 {
        font-size: 16px;
    }

    .skill-level h2 {
        font-size: 18px;
    }

    .skill-level h3 {
        font-size: 14px;
    }

    .skill-level ul {
        margin-left: 15px;
    }
}

/* For mobile devices */
@media (max-width: 480px) {
    .sidebar {
        padding: 10px;
    }

    .content {
        padding: 10px;
    }

    header h1 {
        font-size: 20px;
    }

    header h3 {
        font-size: 14px;
    }

    header p {
        font-size: 12px;
    }

    .skill-level h2 {
        font-size: 16px;
    }

    .skill-level h3 {
        font-size: 12px;
    }

    .skill-level ul {
        margin-left: 10px;
    }

    .skill-level p {
        font-size: 12px;
    }
}